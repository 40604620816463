import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FieldProps } from "formik";
import React from 'react';
import useStyles from "./Styles/MaterialTextFieldUseStyles";

const MaterialDate = <P extends object>({
    field,
    form: {
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
    },
    ...props
}: FieldProps<P>) => {
    const classes = useStyles();
    const get = (o: any, path: string) => path
        .split('.')
        .reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                className={classes.textField}
                fullWidth
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                inputProps={{
                    name: field.name
                }}
                InputLabelProps={{ shrink: true }}
                helperText={touched && touchedField && errors && errorsField || ""}
                error={touched && touchedField && errors && Boolean(errorsField)}
                {...props}
                {...field}
                onChange={(value) => {
                    setFieldTouched(field.name as any, true);

                    if (value != null && (value as unknown) != "Invalid Date") {
                        setFieldValue(field.name as any, value!.toString());
                    }
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default MaterialDate;