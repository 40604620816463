import { Checkbox, createStyles, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, Theme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { navigate } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import React, { Fragment } from 'react';
import ContinueButton from '../components/Controls/ContinueButton';
import CustomRadio from '../components/Controls/CustomRadio';
import CustomRadioGroup from '../components/Controls/CustomRadioGroup';
import EligibilityQuestionsCard from '../components/Controls/EligibilityQuestionsCard';
import HelpButton from '../components/Controls/HelpButton';
import MaterialButton from '../components/Controls/MaterialButton';
import MaterialDate from '../components/Controls/MaterialDate';
import MaterialModal from '../components/Controls/MaterialModal';
import MaterialTextField from '../components/Controls/MaterialTextField';
import ModalContent from '../components/Controls/ModalContent';
import ModalHeader from '../components/Controls/ModalHeader';
import ModalTitle from '../components/Controls/ModalTitle';
import PossessionsList from '../components/Controls/PossessionsList';
import Section from '../components/Controls/Section';
import SectionTitle from '../components/Controls/SectionTitle';
import SelectOverlay from '../components/Controls/SelectOverlay';
import YesNoQuestion from '../components/Controls/YesNoQuestion';
import EligibilityQuestionModel from '../components/Eligibility/EligibilityQuestionsModel';
import Header from '../components/Header';
import { PossessionsModel } from '../components/Possessions/PossessionsModel';
import QuoteHeader from '../components/QuoteResults/QuoteHeader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        displayContainer: {
            padding: '0 30px 30px',
        },
        inputDetails: {
            marginBottom: '30px',
        },
        questionHeader: {
            fontSize: '17px',
            marginBottom: '10px',
        },
        alignRight: {
            textAlign: 'right',
        },
        alignCenter: {
            textAlign: 'center',
        },
        modalList: {
            margin: "0",
            padding: "0",
            fontFamily: "Spartan MB",
            fontSize: "17px",
            listStyleType: "none",
            '& li': {
                padding: theme.spacing(2),
                borderBottom: "2px solid #e5e5e5"
            },
            '& li:last-child': {
                borderBottom: "none"
            },
        },
        labelRoot: {
            color: '#000000',
            fontFamily: "Spartan MB SemiBold",
            fontSize: "1rem",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        radioFormControl: {
            marginTop: '8px',
            marginBottom: '8px',
        },
        radioImage: {
            maxWidth: '100%'
        },
        radioFormLabel: {
            fontFamily: 'Spartan MB',
            [theme.breakpoints.down(374)]: {
                fontSize: '0.6875rem',
            },
            [theme.breakpoints.up(375)]: {
                fontSize: '0.875rem',
            },
            [theme.breakpoints.up(400)]: {
                fontSize: '0.9375rem',
            },
        },
        radioContent: {
            textAlign: 'center',
            [theme.breakpoints.down(374)]: {
                lineHeight: '1rem',
                marginLeft: '5px',
                marginRight: '5px',
                marginBottom: '12px',
            },
            [theme.breakpoints.up(375)]: {
                marginLeft: '8px',
                marginRight: '8px',
                marginBottom: '16px',
                marginTop: '16px',
            },
            [theme.breakpoints.up(400)]: {
                marginLeft: '11px',
                marginRight: '11px',
                marginBottom: '20px',
            },
            marginTop: '8px',
        },
        radioTextOnlyContent: {
            [theme.breakpoints.down(374)]: {
                lineHeight: '1rem',
            },
            textAlign: 'center',
            marginTop: '2px',
            marginBottom: '8px',
            marginLeft: '8px',
            marginRight: '8px',
        },
    }),
);

const initialValues = {
    "example-text-input-name": "",
    "example-text-input-age": "",
    "example-text-input-question": "",
};

const Index = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [answer, setAnswer] = React.useState<boolean | undefined>(undefined);
    const [enableContinue, setEnableContinue] = React.useState<boolean>(false);
    const [titleValue, setTitleValue] = React.useState();

    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: true,
    });

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [name]: event.target.checked });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAnswer = (answer: boolean) => (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnswer(answer);
    }

    const [coverValue, setCoverValue] = React.useState('buildingsContents');

    const handleRadioChange = (newValue: any) => {
        if (newValue !== coverValue) {
            console.log('newValue:', newValue);
            setCoverValue(newValue);
        }
    };

    const handleTitleChange = (newValue: any) => {
        if (newValue !== coverValue) {
            console.log('newValue:', newValue);
            setTitleValue(newValue);
        }
    };

    const handleOverlay = () => {
        navigate("/direct/property/overlay/bedrooms");
    };

    const handleBicycleOverlay = (edit: boolean) => {
        const index = edit ? '0' : '';
        navigate(`/direct/possessions/overlay/bicycle/${index}`);
    };

    const handleHighValueItemOverlay = (edit: boolean) => {
        const index = edit ? '0' : '';
        navigate(`/direct/possessions/overlay/highvalueitem/${index}`);
    };

    const handleEligibilityClick = (answer: boolean) => {
        console.log("Eligibility Click", answer);
    }

    const possessions: PossessionsModel[] = [{
        ItemDescription: "Watch",
        ItemType: "Watch",
        ItemValue: 1000,
        IncludeAtHome: true,
        IncludeAwayFromHome: true,
        IsEditing: false,
    },
    {
        ItemDescription: "Piano",
        ItemType: "Piano",
        ItemValue: 1500,
        IncludeAtHome: true,
        IncludeAwayFromHome: true,
        IsEditing: false,
    }];

    const questions: EligibilityQuestionModel[] = [
        { Number: 1, Question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit" },
        { Number: 2, Question: "Sed egestas sapien quis dolor vehicula, vel rhoncus purus tincidunt" },
        { Number: 3, Question: "Maecenas turpis odio, consequat non dignissim et, interdum non tortor. Morbi id gravida sem" },
        { Number: 4, Question: "Morbi eu purus urna" },
        { Number: 5, Question: "Duis suscipit condimentum pharetra" },
        { Number: 6, Question: "Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos" }
    ];

    return (
        <Fragment>
            <Header />
            <QuoteHeader />
            <Container className={classes.displayContainer} maxWidth='sm'>

                <Formik
                    initialValues={initialValues}
                    validate={values => {
                        console.log(values)
                        setEnableContinue(
                            values["example-text-input-age"] !== '' &&
                            values["example-text-input-name"] !== '' &&
                            values["example-text-input-question"] !== '');
                        let errors = {};
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            console.log(JSON.stringify(values, null, 2));
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ isSubmitting }) => (
                        <main>
                            <Grid item xs={12}>
                                <SectionTitle title={"Your details."} />
                                <FormControl className={classes.radioFormControl} component="fieldset" fullWidth>
                                    <FormLabel
                                        classes={{
                                            root: classes.labelRoot
                                        }}
                                        component="legend"
                                    >
                                        What type of cover do you want?
                                    </FormLabel>
                                    <CustomRadioGroup onClick={handleRadioChange} value={coverValue}>
                                        <CustomRadio radioValue="buildingsContents">
                                            <div className={classes.radioContent}>
                                                <img className={classes.radioImage} src="/img/icons/BuildingsContents.png" />
                                                <span className={classes.radioFormLabel}>Buildings &amp;&nbsp;contents</span>
                                            </div>
                                        </CustomRadio>
                                        <CustomRadio radioValue="buildingsOnly">
                                            <div className={classes.radioContent}>
                                                <img className={classes.radioImage} src="/img/icons/BuildingsOnly.png" />
                                                <span className={classes.radioFormLabel}>Buildings<br />only</span>
                                            </div>
                                        </CustomRadio>
                                        <CustomRadio radioValue="contentsOnly">
                                            <div className={classes.radioContent}>
                                                <img className={classes.radioImage} src="/img/icons/ContentsOnly.png" />
                                                <span className={classes.radioFormLabel}>Contents<br />only</span>
                                            </div>
                                        </CustomRadio>
                                    </CustomRadioGroup>

                                </FormControl>
                                <FormControl className={classes.radioFormControl} component="fieldset" fullWidth>
                                    <FormLabel
                                        classes={{
                                            root: classes.labelRoot
                                        }}
                                        component="legend"
                                    >
                                        What's your title?
                                    </FormLabel>
                                    <CustomRadioGroup onClick={handleTitleChange} value={titleValue}>
                                        <CustomRadio radioValue="mr">
                                            <div className={classes.radioTextOnlyContent}>
                                                <span className={classes.radioFormLabel}>Mr</span>
                                            </div>
                                        </CustomRadio>
                                        <CustomRadio radioValue="mrs">
                                            <div className={classes.radioTextOnlyContent}>
                                                <span className={classes.radioFormLabel}>Mrs</span>
                                            </div>
                                        </CustomRadio>
                                        <CustomRadio radioValue="miss">
                                            <div className={classes.radioTextOnlyContent}>
                                                <span className={classes.radioFormLabel}>Miss</span>
                                            </div>
                                        </CustomRadio>
                                        <CustomRadio radioValue="ms">
                                            <div className={classes.radioTextOnlyContent}>
                                                <span className={classes.radioFormLabel}>Ms</span>
                                            </div>
                                        </CustomRadio>
                                    </CustomRadioGroup>
                                </FormControl>

                                <Form noValidate className={classes.inputDetails}>
                                    <Field id="example-text-input-name"
                                        name="example-text-input-name"
                                        label="Name"
                                        component={MaterialTextField}
                                    />
                                    <Field id="example-text-input-age"
                                        name="example-text-input-age"
                                        label="Age"
                                        component={MaterialTextField}
                                    />
                                    <Field id="example-text-input-question"
                                        name="example-text-input-question"
                                        label="Question"
                                        component={MaterialTextField}
                                    />

                                    <Field name="PolicyStartDate"
                                        id={`policy-start-date`}
                                        label={
                                            <div>
                                                When you would like your policy to start.<br />This must be within the next 60 days.
                                            </div>
                                        }
                                        component={MaterialDate}
                                        placeholder="dd / mm / yyyy"
                                    />
                                    <Section sectionTitle={"So we can send you a quote"}>
                                        <Fragment>
                                            <Field id="example-text-input-email"
                                                name="example-text-input-email"
                                                label="Your email"
                                                placeholder="example@email.com"
                                                component={MaterialTextField}
                                            />
                                            <Field id="example-text-input-phone"
                                                name="example-text-input-phone"
                                                label="Phone number (optional)"
                                                placeholder="+44"
                                                component={MaterialTextField}
                                            />
                                        </Fragment>
                                    </Section>

                                    <Section sectionTitle={"So we can send you a quote"} helpText={"some help"} helpTitle={"some title"}>
                                        <Fragment>
                                            <Field id="example-text-input-email2"
                                                name="example-text-input-email2"
                                                label="Your email"
                                                placeholder="example@email.com"
                                                component={MaterialTextField}
                                            />
                                            <Field id="example-text-input-phone2"
                                                name="example-text-input-phone2"
                                                label="Phone number (optional)"
                                                placeholder="+44"
                                                component={MaterialTextField}
                                            />
                                            <SelectOverlay label="Contents excess" value={"£250"} onClick={() => console.log("clicked Contents SelectOverlay")} helpText="help text" helpTitle="help title" />
                                            <SelectOverlay boldLabel={true} label="Buildings excess " value={"£200"} onClick={() => console.log("clicked Buildings SelectOverlay")} helpText="help text" helpTitle="help title" />
                                        </Fragment>
                                    </Section>

                                    <Section sectionTitle={"Buildings."} helpText={"some help"} helpTitle={"some title"}>
                                        <YesNoQuestion questionText={"Would you like to include accidental damage on your buildings insurance?"} onClick={(value) => console.log("clicked", value)} />
                                    </Section>

                                    <Section sectionTitle={"Policy documents by email?"} helpText={"some help"} helpTitle={"some title"}>
                                        <YesNoQuestion questionText={"Do you want to receive your policy documents by email?"} onClick={(value) => console.log("clicked", value)} />
                                    </Section>

                                    <Section sectionTitle={"Home Emergency."} helpText={"some help"} helpTitle={"some title"}>
                                        <YesNoQuestion
                                            questionText={"Would you like to include Home Emergency Cover as part of your policy?"}
                                            onClick={(value) => console.log("clicked", value)}
                                            additionalInformation={
                                                [
                                                    'Lorem ipsum dolor sit amet, consectetur. ',
                                                    'Adipiscing elit, sed do eiusmod tempor.',
                                                    'Incididunt ut labore et dolore magna aliqua.'
                                                ]} />
                                    </Section>

                                </Form>
                            </Grid>

                            <HelpButton helpText={"This is some help text"} helpTitle={"This is a help title"} className={classes.modalList}></HelpButton>

                            <MaterialButton onClick={handleOverlay}>
                                Overlay
                            </MaterialButton>

                            <MaterialButton onClick={() => handleBicycleOverlay(false)}>
                                Bicycle Overlay New
                            </MaterialButton>

                            <MaterialButton onClick={() => handleBicycleOverlay(true)}>
                                Bicycle Overlay Edit
                            </MaterialButton>

                            <MaterialButton onClick={() => handleHighValueItemOverlay(false)}>
                                High Value Item Overlay New
                            </MaterialButton>

                            <MaterialButton onClick={() => handleHighValueItemOverlay(true)}>
                                High Value Item Overlay Edit
                            </MaterialButton>

                            <PossessionsList
                                title="Testing Styles"
                                icon={<SmartphoneIcon />}
                                helpText="Testing Help Text"
                                helpTitle="Testing Help Title"
                                noPossessionsText="Would you like cover any bicycles worth more than £1000?"
                                possessions={possessions}
                                addChangeHandle={() => console.log("Change")}
                            />

                            <PossessionsList
                                title="Testing Styles"
                                icon={<SmartphoneIcon />}
                                helpText="Testing Help Text"
                                helpTitle="Testing Help Title"
                                noPossessionsText="Would you like cover any bicycles worth more than £1000?"
                                possessions={possessions}
                                addChangeHandle={() => console.log("Change")}
                            />

                            <EligibilityQuestionsCard
                                title="The Property"
                                questions={questions}
                                handleClick={handleEligibilityClick}
                            />

                            <Grid item xs={12} className={classes.alignCenter}>
                                <ContinueButton disabled={!enableContinue} onClick={handleOpen}>Tailor your quote</ContinueButton>
                            </Grid>
                            <SectionTitle title={"Let's get your cheapest home insurance quote."} icon={"/img/Buildings_Contents_Uinsure-small.png"} />
                            <SectionTitle title={"Let's get your cheapest home insurance quote."} icon={"/img/Buildings_Contents_Uinsure-small.png"} subHeading={"Now with a new Subheading"} />




                        </main>
                    )}
                </Formik>
                <MaterialModal
                    open={open}
                    onClose={handleClose}
                >
                    <Fragment>
                        <ModalHeader showClose={true} onClick={handleClose}>
                            <ModalTitle id="simple-modal-title">
                                <Fragment>
                                    What is your answer<br />to the question?
                                </Fragment>
                            </ModalTitle>
                        </ModalHeader>
                        <ModalContent>
                            <ul className={classes.modalList}>
                                <li>Option one shown here</li>
                                <li>Option two here</li>
                                <li>Option three shown here</li>
                                <li>Option four here</li>
                                <li>Option five shown here</li>
                                <li>Option six here</li>
                                <li>Option seven shown here</li>
                            </ul>
                        </ModalContent>
                    </Fragment>
                </MaterialModal>

                <FormGroup>
                    <FormControlLabel disabled control={<Checkbox value="checkedD" />} label="I agree these are correct" />
                    <FormControlLabel
                        control={
                            <Checkbox checked={state.checkedA} onChange={handleChange('checkedA')} value="checkedA" />
                        }
                        label="Secondary"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                                value="checked"
                            />
                        }
                        label="I agree these are correct"
                    />
                </FormGroup>
            </Container>
        </Fragment >
    );
};

export default Index;